import ApolloClient from "apollo-boost";
import Vue from "vue";

import VueApollo from "vue-apollo";
import { baseURL } from "./pages/utils";

Vue.use(VueApollo);

export const apolloClient = new ApolloClient({
  uri: `${baseURL}graphql`
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});
