declare var AdyenCheckout;

export enum Environment {
  Demo = "Demo",
  Live = "Live",
}

console.log(process.env.REACT_APP_REMOTE_ENDPOINT);

export const baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_REMOTE_ENDPOINT ?? "http://localhost:4005/"
    : "/";

export function isDemo() {
  return (window as any).paymentEnv === "demo";
}

export const css = (text) => text[0];
export const html = (text) => text[0];

const urlPaymentTypeMap = {
  // "/": "ideal"
  "/cc": "card",
  // "/applepay": "applepay",
};

export const getPaymentType = () =>
  urlPaymentTypeMap[location.pathname.replace(/\/registration(\/demo)?/, "")];

export function getEnvironment() {
  return (window as any).paymentEnv === "demo"
    ? Environment.Demo
    : Environment.Live;
}

const originKeys = {
  "topgolf-demo.loyyo.nl:demo":
    "pub.v2.8216054556470988.aHR0cHM6Ly90b3Bnb2xmLWRlbW8ubG95eW8ubmw.BXjUCGYdTirCEWeQ76as7FRSxPl2ORE92weyh1Rl7Z0",
  "topgolf-demo.loyyo.nl:live":
    "pub.v2.4115686549223817.aHR0cHM6Ly90b3Bnb2xmLWRlbW8ubG95eW8ubmw.EY0rmHr3Ym3xwindFZzYzNxEnozI6TjAQRdAOhHOr3Y",
  "localhost:1234:demo":
    "pub.v2.8015616616893921.aHR0cDovL2xvY2FsaG9zdDoxMjM0.h6I-p5mrSD3SLPIN0SeP62I0lZZ5kHN9L0vnj8gMd-U",
  // must be on https
  "localhost:1234:live":
    "pub.v2.4115686549223817.aHR0cHM6Ly9sb2NhbGhvc3Q6MTIzNA.OAZqgGNrZ5wa9S0UpK4naEtfwVttJCHL6GeRqdW4coA",
};

const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

export async function setupAdyen(
  handleChange,
  paymentMethods,
  validateAppleMerchant,
  {
    amount = 2,
    type,
    getAmount,
  }: {
    userId?: string;
    amount?: number;
    type: "ideal" | "card" | "applepay";
    getAmount?: () => number;
  }
) {
  await wait(500);

  console.log("paymentMethods", paymentMethods);
  const configuration = {
    locale: navigator.language,
    environment: isDemo() ? "test" : "live",
    originKey: originKeys[location.host + ":" + (window as any).paymentEnv],
    paymentMethodsResponse: paymentMethods.paymentMethods
      ? paymentMethods.paymentMethods
      : paymentMethods,
  };

  console.log(configuration);

  const checkout = new AdyenCheckout(configuration);

  await wait(500);

  const paymentElement = document.querySelector("#payment");

  paymentElement.innerHTML = "";

  // Add a css class for speficic styling
  paymentElement.classList.add(`payment-${type}`);
  // If we click through the payment methods we should remove the old one
  paymentElement.classList.remove(
    `payment-${type === "ideal" ? "card" : "ideal"}`
  );

  if (type === "applepay") {
    const config = {
      currencyCode: "EUR",
      amount,
      countryCode: "NL",
      configuration: {
        merchantName: "PuntjeEcom",
        merchantIdentifier: "merchant.com.adyen.puntje.test",
      },
      buttonType: "plain",
      // buttonColor: "white-with-line",
      supportedNetworks: ["maestro", "masterCard", "visa"],
      version: 4,
      onValidateMerchant: async (resolve, reject, validationURL) => {
        const data = await validateAppleMerchant(
          validationURL,
          isDemo() ? Environment.Demo : Environment.Live
        );
        data.epochTimestamp = Number(data.epochTimestamp);
        data.expiresAt = Number(data.expiresAt);
        resolve(data);
      },
      onPaymentMethodSelected: (resolve, reject, event) => {
        resolve({
          newTotal: {
            label: "Loyyio Payment",
            amount: String((getAmount ? getAmount() : amount) / 100),
            type: "final",
          },
        });
      },
      onChange: (paymentMethod) => {
        handleChange(paymentMethod);
      },
    };

    const applepay = checkout.create(type, config);

    applepay
      .isAvailable()
      .then(() => {
        applepay.mount("#payment");
      })
      .catch(() =>
        alert(
          Boolean((window as any).safari)
            ? "ApplePay is not available"
            : "ApplePay is not available in this browser. Please open this page in Safari."
        )
      );
  } else {
    const config = {
      onChange: (paymentMethod) => {
        handleChange(paymentMethod);
      },
    };
    checkout.create(type, config).mount("#payment");
  }
}

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
