import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { gql } from "apollo-boost";
import Vue from "vue";

import {
  StartAdyenPayment,
  StartAdyenPaymentVariables,
} from "./__generated__/StartAdyenPayment";

const startAdyenPayment = gql`
  mutation StartAdyenPayment($amount: Int!) {
    pos_startPaymentAdyen(amount: $amount) {
      payment
    }
  }
`;

export default Vue.component("MerchantPOS", {
  template: require("./merchant-pos.vue.html"),
  setup() {
    const { mutate } = useMutation<
      StartAdyenPayment,
      StartAdyenPaymentVariables
    >(startAdyenPayment);

    async function handlePaymentStart() {
      const data = await mutate({ amount: 30 });

      console.log(data);
    }
    return { handlePaymentStart };
  },
});
