import "babel-polyfill";

import "./components";

import { DefaultApolloClient } from "@vue/apollo-composable";
import VueCompositionApi, { provide } from "@vue/composition-api";
import page from "page";
import SuiVue from "semantic-ui-vue";
import Vue from "vue";
import VueIntl from "vue-intl";

import { i18messages } from "./pages/i18messages";
import { isDemo } from "./pages/utils";
import { apolloClient, apolloProvider } from "./services";

Vue.use(VueCompositionApi);
Vue.use(SuiVue);
Vue.use(VueIntl);

Object.entries(i18messages).forEach(([language, messages]) => {
  (Vue as any).registerMessages(language, messages);
});

// some libs (e.g. composition api) access the default value
(Vue as any).default = Vue;
(Vue as any).setLocale(
  i18messages[navigator.language] ? navigator.language : "en-US"
);

const app = new Vue({
  el: "#app",
  apolloProvider,
  setup() {
    provide(DefaultApolloClient, apolloClient);
    return {};
  },
  data: {
    route: null,
    ViewComponent: { render: (h) => h("div", "loading...") },
  },
  render(h) {
    return h(this.ViewComponent, { key: this.route });
  },
});

const routes = {
  // "/": require("./pages/registration.vue").default,
  "/cc": require("./pages/registration.vue").default,
  // "/applepay": require("./pages/registration.vue").default,
  "/card-landing": require("./pages/user-form.vue").default,
  "/": require("./pages/user-form.vue").default,
  "/account-created": require("./pages/account-created.vue").default,
  "/merchant-pos": require("./pages/merchant-pos.vue").default,
  "/merchant": require("./pages/merchant.vue").default,
};

// page.base(isDemo() ? "/registration/demo" : "/registration");
page.base("/registration");

Object.keys(routes).forEach((route) => {
  const Component = routes[route];
  page(route, () => {
    app.route = route;
    app.ViewComponent = Component;
  });
});
page("*", () => (app.ViewComponent = require("./pages/404.vue").default));
page();
