import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useResult,
} from "@vue/apollo-composable";
import { onMounted, reactive } from "@vue/composition-api";
import { gql } from "apollo-boost";
import page from "page";
import queryString from "query-string";
import Vue from "vue";

import { SaveUser, SaveUserVariables } from "./__generated__/SaveUser";
import { UserQuery, UserQueryVariables } from "./__generated__/UserQuery";

const usersQuery = gql`
  query UserQuery($email: String!) {
    doesUserExist(email: $email)
  }
`;

const saveUserMutation = gql`
  mutation SaveUser($email: String!, $customerKey: String) {
    registerSimple(email: $email, customerKey: $customerKey) {
      id
      email
    }
  }
`;

export default Vue.component("UserForm", {
  template: require("./user-form.vue.html"),
  setup(props) {
    const { mutate: saveUser } = useMutation<SaveUser, SaveUserVariables>(
      saveUserMutation,
      null
    );

    const query = queryString.parse(location.search);

    const state = reactive<{
      email: string;
      userToLink: null | string;
      hasRecurringDetails: boolean;
      paymentComplete: boolean;
      loading: boolean;
    }>({
      email: "",
      userToLink: null,
      hasRecurringDetails: false,
      paymentComplete: false,
      loading: false,
    });

    async function saveUserData() {
      console.log("result");

      // const client = resolveClient();
      //
      // const {data} = await client.query<UserQuery, UserQueryVariables>({
      //   query: usersQuery,
      //   variables: { email: state.email },
      // });
      //
      // console.log(data.doesUserExist);
      //
      // if (data.doesUserExist) {

      const customerKey = new URLSearchParams(location.search).get("sr");

      try {
        state.loading = true;
        const {
          data: {
            registerSimple: { id },
          },
        } = await saveUser({
          email: state.email,
          customerKey: customerKey || undefined,
        });

        localStorage.setItem("userId", id);

        // if we have a customer key, then we know the person comes from a pos so redirect directly to the account page
        if (customerKey) {
          page.show("/account-created?source=pos");
        } else {
          page.show("/cc");
        }
      } catch (error) {
        alert(error.message);
      } finally {
        state.loading = false;
      }
    }

    onMounted(() => {
      if (query.resultCode === "authorised") {
        state.paymentComplete = true;
      }
    });

    return { saveUserData, state };
  },
});
