import Vue from "vue";
import { Plugin as Fragment } from "vue-fragment";

import { html } from "../pages/utils";

Vue.use(Fragment);

const template = html`
  <fragment>{{ $formatMessage({id: id}, values) }}</fragment>
`;

Vue.component("formatm", {
  props: {
    id: {
      type: String,
      required: true,
    },
    values: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  template,
});
