import page from "page";
import Vue from "vue";

import { css, getPaymentType, html } from "../pages/utils";

const template = html`
  <div class="ui padded stackable grid">
    <component is="style" v-html.once="style"> </component>
    <slot></slot>
  </div>
`;

const style = css``;

Vue.component("main-layout", {
  template,
  props: {
    navBar: { type: Boolean, default: true },
    showHeadline: { type: Boolean, default: true },
  },
  setup() {
    return {
      goTo(route: string) {
        page(route);
      },
      style,
      scrollDown() {
        document
          .querySelector(".right-column")
          ?.scrollIntoView({ behavior: "smooth" });
      },
      paymentType: getPaymentType() || "no",
      location: window.location,
      isSafari: Boolean((window as any).safari),
      puntjeLogo: require("../../assets/puntje-logo.svg"),
      handLogo: require("../../assets/hand.png"),
    };
  },
});
