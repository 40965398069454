import { onMounted } from "@vue/composition-api";
import page from "page";
import Vue from "vue";

export default Vue.component("AccountCreated", {
  template: require("./account-created.vue.html"),
  setup() {
    onMounted(() => {
      const qs = new URLSearchParams(location.search);
      if (qs.get("shopperReference")) {
        page.show("/account-created");
      }
    });

    return {};
  },
});
