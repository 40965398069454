const english = {
  "navbar.transactions": "Transactions",
  "navbar.creditCards": "Credit",
  "navbar.iDEAL": "iDEAL",

  "404.headline": "This page does not exist",

  // TODO: translate
  "landingpage.left.headline": "Sparen met je bankpas",
  "landingpage.left.body":
    "Eindelijk punten paren zonder gedoe! Wij maken van een pinpas of creditkaart een spaarpas. Klanten sparen zo automatisch punten bij merchants. Direct tijdens de betaling, zonder extra handelingen aan de kassa.",

  "landingpage.left.download-link": "More info",
  "landingpage.left.cta": "Let's start",

  "registration.headline": "Connect your creditcard",
  "registration.subheadline":
    "Make a selection below so connect your bankcard. We connect your card with a payment of 2ct. You will receive loyalty points in return.",
  "registration.cta": "Connect now!",
  "registration.success.headline":
    "Congrats! You connected your card. We have added loyalty points to your card.",
  "registration.form.headline":
    "Now enter your email to finalize your account, so you can start saving!",
  "registration.form.cta": "Create Account",
  "registration.form.or": "Or if you already have an account",

  "account-created.success.headline":
    "You successfully registered for our loyalty program!",
  "account-created.success.subheadline":
    "Use your connected bankcard for payments in our store and collect loyalty points automatically. We will send you an email with your new balance after every transaction.",
  "account-created.success.cta": "Shop now",

  "registration.error.alert": "Payment did not succeed:\n{errorBody}",
  "transactions.headline": "Pay what you want for this demo",
  "transactions.form.help":
    "Select one of the registered users so we can use their shopperReference",
  "transactions.form.selectUser": "Select User",
  "transactions.form.amount.placeholder": "Amount in ct",
  "transactions.form.ctaWithValue": "Pay {amount} €",
  "transactions.form.ctaBare": "Pay",
  "transactions.form.userSelection":
    "{name}: {amount, plural, one {# point} other {# points}} using {paymentMethod}/...{cardSummary}",

  "transactions.success.modal.header": "Payment succeeded",
  "transactions.success.modal.body":
    "Card {shopperReference} got some loyalty points",

  "transactions.payment.error":
    "Payment did not succeed. This is the answer we got from adyen:\n{errorBody}",
};

export const i18messages: { [key: string]: typeof english } = {
  nl: {
    "navbar.transactions": "Transacties",
    "navbar.creditCards": "Creditkaart",
    "navbar.iDEAL": "Pinpas",

    "404.headline": "Deze pagina bestaat niet",

    "landingpage.left.headline": "Sparen met je bankpas",
    "landingpage.left.body":
      "Eindelijk punten paren zonder gedoe! Wij maken van een pinpas of creditkaart een spaarpas. Klanten sparen zo automatisch punten bij merchants. Direct tijdens de betaling, zonder extra handelingen aan de kassa.",
    "landingpage.left.download-link": "Meer info",
    "landingpage.left.cta": "Start nu!",

    "registration.headline": "Koppel je creditkaart",
    "registration.subheadline":
      "Kies hieronder uit één van de twee opties. Koppel de bankpas die je het meest gebruikt in onze winkel. De koppeling wordt gemaakt d.m.v. een 2ct betaling.",
    "registration.cta": "Koppel nu!",
    "registration.success.headline":
      "Je bankpas is met succes gekoppeld. We hebben er alvast verzamel punten op gezet.",
    "registration.form.headline":
      "Iedere keer wanneer jij deze bankpas gebruikt in onze winkel krijg je binnen enkele minuten een e-mail met je nieuwe puntentotaal. De laatste stap is dus het toevoegen van jouw e-mailadres.",
    "registration.form.cta": "Account aanmaken!",
    "registration.form.or": "Of als u al een account heeft",
    "transactions.headline": "Betaal wat je wilt voor deze demo",
    "registration.error.alert": "De betaling is niet gelukt:\n{errorBody}",

    "account-created.success.headline":
      "Jouw account is aangemaakt. Je kunt nu sparen met je bankpas!",
    "account-created.success.subheadline":
      "Gebruik de bankpas gekoppeld aan jouw account voor betalingen in de winkel en verzamel automatisch punten! Na een aankoop ontvang je een e-mail met daarin instructies hoe je punten kunt inwisselen. Happy savings!",
    "account-created.success.cta": "Bekijk beloningen",

    "transactions.form.help":
      "Selecteer een van de geregistreerde gebruikers zodat we hun shopperReference kunnen gebruiken",
    "transactions.form.selectUser": "Selecteer Gebruiker",
    "transactions.form.amount.placeholder": "Bedrag in ct",
    "transactions.form.ctaWithValue": "Betaal {amount}",
    "transactions.form.ctaBare": "Betaal",
    "transactions.form.userSelection":
      '{name}: {amount} punten met "{paymentMethod}"',

    "transactions.success.modal.header": "Betaling geslaagd",
    "transactions.success.modal.body":
      "Gebruiker {shopperReference} heeft enkele punten",

    "transactions.payment.error": "De betaling is niet gelukt:\n{errorBody}",
  },
  "en-US": english,
  "en-GB": english,
};
