import queryString from "querystring";

import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { reactive, watchEffect } from "@vue/composition-api";
import { gql } from "apollo-boost";
import page from "page";
import Vue from "vue";

import {
  CreateRegisterPayment,
  CreateRegisterPaymentVariables,
} from "./__generated__/CreateRegisterPayment";
import {
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables,
} from "./__generated__/PaymentMethodsQuery";
// import {
//   ValidateAppleMerchant,
//   ValidateAppleMerchantVariables,
// } from "./__generated__/ValidateAppleMerchant";
import {
  Environment,
  getEnvironment,
  getPaymentType,
  setupAdyen,
} from "./utils";

export const paymentMethodsQuery = gql`
  query PaymentMethodsQuery($amountInCent: Int!, $shopperReference: String) {
    paymentMethods(
      amountInCent: $amountInCent
      shopperReference: $shopperReference
    ) {
      groups {
        name
        types
      }
      paymentMethods {
        brands
        supportsRecurring
        details {
          key
          type
          items {
            id
            name
          }
        }
        name
        type
      }
    }
  }
`;

const createRegisterPaymentMutation = gql`
  mutation CreateRegisterPayment(
    $userId: String!
    $input: CreateRegisterPaymentInput!
  ) {
    registerWithPaymentSimple(userId: $userId, input: $input) {
      redirectURL
      resultCode
      shopperReference
    }
  }
`;

// export const validateAppleMerchantMutation = gql`
//   mutation ValidateAppleMerchant($validationURL: String, $env: Environment!) {
//     validateAppleMerchant(validationURL: $validationURL, env: $env) {
//       epochTimestamp
//       expiresAt
//       merchantSessionIdentifier
//       nonce
//       merchantIdentifier
//       domainName
//       displayName
//       signature
//     }
//   }
// `;

export default Vue.component("Registration", {
  setup() {
    const state = reactive({
      paymentMethod: null,
      type: "card",
      registrationLoading: false,
    });

    // const { mutate: validateMerchant } = useMutation<
    //   ValidateAppleMerchant,
    //   ValidateAppleMerchantVariables
    // >(validateAppleMerchantMutation, null);

    const { mutate: createPayment } = useMutation<
      CreateRegisterPayment,
      CreateRegisterPaymentVariables
    >(createRegisterPaymentMutation, null);

    const { result, loading } = useQuery<
      PaymentMethodsQuery,
      PaymentMethodsQueryVariables
    >(paymentMethodsQuery, {
      amountInCent: 2,
    });

    const data = useResult(result);

    watchEffect(() => {
      if (data.value) {
        setupAdyen(
          (paymentMethod) => {
            state.paymentMethod = paymentMethod.data.paymentMethod;
            if (state.type === "applepay") {
              register();
            }
          },
          data.value,
          async (url: string, env: Environment) => {
            // const { data } = await validateMerchant({
            //   validationURL: url,
            //   env,
            // });
            // return data.validateAppleMerchant;
          },
          {
            amount: 2,
            getAmount: () => 2,
            type: state.type as any,
          }
        );
      }
    });

    async function register() {
      if (!state.paymentMethod) {
        return;
      }
      if (state.paymentMethod["applepay.token"]) {
        state.paymentMethod.applepayToken =
          state.paymentMethod["applepay.token"];
        delete state.paymentMethod["applepay.token"];
      }

      try {
        state.registrationLoading = true;
        const { data } = await createPayment({
          userId: localStorage.getItem("userId"),
          input: {
            paymentMethod: state.paymentMethod,
            returnURL: location.href.replace(/\/?$/, "") + "/account-created",
          },
        });

        const {
          resultCode,
          shopperReference,
          redirectURL,
        } = data.registerWithPaymentSimple;

        // An credit card/applepay payment which has no redirect but rather the success code comes in the ajax response
        if (resultCode && resultCode.toLowerCase() === "authorised") {
          page.show(
            "/account-created" +
              "?" +
              queryString.stringify({
                shopperReference,
                resultCode: "authorised",
              })
          );
        } else {
          // An iDEAL payment
          window.location.href = redirectURL;
        }
      } catch (error) {
        alert(error.message);
      } finally {
        state.registrationLoading = false;
      }
    }

    return {
      data,
      loading,
      state,
      paymentMethod: state.paymentMethod,
      paymentType: state.type,
      register,
    };
  },

  template: require("./registration.vue.html"),
});
