import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { gql } from "apollo-boost";
import { sumBy } from "lodash";
import page from "page";
import queryString from "query-string";
import Vue from "vue";

import {
  CreatePaymentMutation,
  CreatePaymentMutationVariables,
} from "./__generated__/CreatePaymentMutation";
import { LinkedCardsForUserQuery } from "./__generated__/LinkedCardsForUserQuery";
import { LinkedCardsQuery } from "./__generated__/LinkedCardsQuery";
import {
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables,
} from "./__generated__/PaymentMethodsQuery";
import { paymentMethodsQuery } from "./registration.vue";
import { createPaymentMutation } from "./super-merchant.vue";
import { Environment, getEnvironment, setupAdyen } from "./utils";

const linkedCardsForUserQuery = gql`
  query LinkedCardsForUserQuery($userId: String!) {
    admin_user_cards(userId: $userId) {
      shopperReference
      paymentMethod
      cardInfos
    }
  }
`;

export default Vue.component("MerchantPage", {
  template: require("./merchant.vue.html"),
  setup() {
    const state = reactive<{
      shopperReference: null | string;
      amountInCent: null | number;
      paymentMethod: any;
      successMessage: { shopperReference: string };
      type: "card" | "ideal" | "applepay";
    }>({
      shopperReference: Math.random().toString(),
      amountInCent: 10,
      paymentMethod: null,
      successMessage: null,
      type: "card",
    });

    const {
      result: linkedCardsResult,
      loading: linkedCardsLoading,
    } = useQuery<LinkedCardsForUserQuery>(
      linkedCardsForUserQuery,
      {
        userId: localStorage.getItem("userId"),
      },
      {
        pollInterval: 10000,
      }
    );

    const { mutate: createPayment } = useMutation<
      CreatePaymentMutation,
      CreatePaymentMutationVariables
    >(createPaymentMutation, null);

    const { result: paymentMethodsResult, variables } = useQuery<
      PaymentMethodsQuery,
      PaymentMethodsQueryVariables
    >(paymentMethodsQuery, {
      // Just a dummy value to get payment methods without waiting for the exact input
      amountInCent: 10,
    });

    async function submitPayment() {
      if (paymentMethodsData && state.paymentMethod) {
        if (state.paymentMethod["applepay.token"]) {
          state.paymentMethod.applepayToken =
            state.paymentMethod["applepay.token"];
          delete state.paymentMethod["applepay.token"];
        }

        const { data } = await createPayment({
          input: {
            shopperReference: "not-needed",
            amountInCent: state.amountInCent,
            paymentMethod: state.paymentMethod,
            returnUrl: location.href,
          },
        });

        const {
          redirectURL,
          resultCode,
          shopperReference,
        } = data.createPayment;

        if (resultCode && resultCode.toLowerCase() === "authorised") {
          state.successMessage = { shopperReference };
        } else {
          window.location.href = redirectURL;
        }
      }
    }

    onMounted(() => {
      const query = queryString.parse(location.search);

      if (query && query.resultCode === "authorised") {
        state.successMessage = {
          shopperReference: query.shopperReference as string,
        };

        page.show("/merchant");
      }
    });

    const paymentMethodsData = useResult(paymentMethodsResult);

    // const isApplePay = computed(
    //   () => state.card?.paymentMethod?.includes("applepay") || false
    // );

    watch(paymentMethodsData, () => {
      if (
        paymentMethodsData.value
        // &&
        // linkedCards.value &&
        // state.shopperReference
      ) {
        setupAdyen(
          ({ data }) => {
            state.paymentMethod = data.paymentMethod;

            // On applepay there is no submit button but just the one from apple, so trigger a payment on next render
            // if (isApplePay.value) {
            //   submitPayment();
            // }
          },
          paymentMethodsData.value,
          async (url: string, env: Environment) => {
            // const { data } = await validateMerchant({
            //   validationURL: url,
            //   env,
            // });
            // return data.validateAppleMerchant;
          },
          {
            userId: "state.card.shopperReference",
            amount: 1000,
            type: "card",
            getAmount: () => state.amountInCent,
          }
        );
      }
    });

    function mapLinkedCards(linkedCards: LinkedCardsQuery["admin_cards"], t) {
      return linkedCards.map((card) => ({
        key: card.shopperReference,
        value: card.shopperReference,
        text: t(
          { id: "transactions.form.userSelection" },
          {
            name: card.User?.email
              ? `${card.User?.email} (${card.shopperReference.slice(0, 6)})`
              : "no-email",
            amount: sumBy(card.Transaction, "points"),
            paymentMethod: card.paymentMethod || "ideal",
            cardSummary: JSON.parse(card.cardInfos || "{}").cardSummary,
          }
        ),
      }));
    }

    return {
      submitPayment,
      mapLinkedCards,
      linkedCardsResult,
      data: paymentMethodsData,
      paymentType: state.type,
      state,
      isApplePay: false,
    };
  },
});
